'use client';
// All Imports
import { Spinner } from '@/components/icons';
import Button from '@/components/ui/Button';
import InputField from '@/components/ui/InputField';
import Link from 'next/link';
import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useLoginUserMutation } from '@/store/features/auth/authApi';
import { Lock, Mail } from './icons/huge-icons';

const LoginForm = () => {
  const [loginUser, { isLoading, isSuccess, error }] = useLoginUserMutation();

  // Handling Use Effect
  useEffect(() => {
    if (isSuccess) {
      toast.success('Login Successful');
    }
    if (error) {
      if ('data' in error) {
        toast.error(
          (error as any)?.status >= 500
            ? 'Something went wrong please try later!'
            : (error as any).data.message,
        );
      }
    }
  }, [isSuccess, error]);

  // Handling Login Form
  const LoginUserInputSchema = Yup.object().shape({
    email: Yup.string()
      .email('Must be a Valid Email')
      .required('Email Required...'),
    password: Yup.string().required('Password Required...'),
  });
  const { values, handleBlur, handleChange, handleSubmit, touched, errors } =
    useFormik({
      initialValues: {
        email: '',
        password: '',
      },
      validationSchema: LoginUserInputSchema,
      async onSubmit({ email: username, password }) {
        await loginUser({ username, password });
      },
    });
  return (
    <form
      onSubmit={handleSubmit}
      className="z-10 mt-16 flex w-full flex-col gap-5 sm:mt-10"
    >
      <InputField
        leftIcon={<Mail className="h-[5.33vw] w-[5.33vw] text-gray-500" />}
        labelName="Email"
        type="email"
        placeholder="Email"
        required
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        isError={touched.email && errors.email !== null}
        error={errors.email}
        name="email"
        id="email"
      />
      <InputField
        leftIcon={<Lock className="h-[5.33vw] w-[5.33vw] text-gray-500" />}
        labelName="Password"
        type="password"
        required
        placeholder="Password"
        name="password"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        isError={touched.password && errors.password !== null}
        error={errors.password}
        id="password"
      />
      <Link
        href="/forgot-password"
        className="place-self-end text-[3.73vw] text-gray-600 hover:text-secondary-600"
      >
        Forgot Password?
      </Link>
      <Button
        className="p-[3.2vw] text-[3.73vw] font-semibold"
        type="submit"
        disabled={isLoading}
        variant="PRIMARY"
      >
        Login {isLoading && <Spinner className="text-white" />}
      </Button>
    </form>
  );
};

export default LoginForm;
