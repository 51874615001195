'use client';
import { cn } from '@/utils/class-merge';
import { ComponentProps, ReactNode, useState } from 'react';
import { EyeCloseIcon, EyeOpenIcon } from '../icons/huge-icons';
import { Spinner } from '../icons';

interface IInputFieldProps extends ComponentProps<'input'> {
  id: string;
  name: string;
  type?: string;
  labelName?: string;
  leftIcon?: ReactNode;
  error?: string;
  isError?: boolean;
  variant?: 'PHONE' | 'DEFAULT';
  rightIcon?: ReactNode;
  containerClassName?: string;
  required?: boolean;
  onRightIconClick?: () => void;
  maincontainerClassName?: string;
  rightIconDisabled?: boolean;
  loading?: boolean;
}

const InputField = ({
  required,
  id,
  name,
  labelName,
  isError,
  error,
  variant = 'DEFAULT',
  leftIcon,
  className,
  type = 'text',
  rightIcon,
  containerClassName,
  onRightIconClick,
  maincontainerClassName,
  rightIconDisabled = false,
  loading,
  ...props
}: IInputFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <label
      htmlFor={id || ''}
      className={cn(
        'group flex w-full flex-col gap-[1vw] text-[3.73vw] font-medium',
        maincontainerClassName,
      )}
    >
      {labelName && (
        <span>
          {`${labelName}: `}
          {required && (
            <span className="text-[3.73vw] font-bold text-red-500">*</span>
          )}
        </span>
      )}
      <div
        className={cn(
          'flex items-center gap-[0.5vw] rounded-[2.8vw] border border-slate-200/60 bg-[#F6F5F5] p-[0.5vh] px-[1.5vh] ring-secondary-500  group-focus-within:ring-1 group-active:ring-1',
          containerClassName,
        )}
      >
        {/* <Mail /> */}
        {leftIcon}
        <input
          id={id}
          name={name}
          type={type !== 'password' ? type : showPassword ? 'text' : 'password'}
          onWheel={(e) => type === 'number' && e.currentTarget.blur()}
          {...props}
          className={cn(
            'w-full flex-1 border border-none bg-transparent !p-[2vw] font-normal text-neutral-900 outline-none placeholder:!text-neutral-600',
            className,
          )}
        />
        {type === 'password' ? (
          <span
            onClick={handleShowPassword}
            className="hover:text-primary text-gray-500"
          >
            {showPassword ? (
              <EyeCloseIcon className="h-[5.33vw] w-[5.33vw]" />
            ) : (
              <EyeOpenIcon className="h-[5.33vw] w-[5.33vw]" />
            )}
          </span>
        ) : (
          !!rightIcon && (
            <button
              className={`${rightIconDisabled ? 'opacity-40' : ''}`}
              disabled={rightIconDisabled}
              onClick={onRightIconClick}
            >
              {loading ? (
                <Spinner className="h-5 w-5 text-secondary-600" />
              ) : (
                rightIcon
              )}
            </button>
          )
        )}
      </div>
      {isError && (
        <span className="-mt-1 ml-1 text-[4vw] font-normal text-red-500">
          {error}
        </span>
      )}
    </label>
  );
};

export default InputField;
