import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/animal_lover_art.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/sunshine_art.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/GoogleSignInButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SpiralIcon"] */ "/app/src/components/icons/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/LoginForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/ui/LinkButton.tsx");
