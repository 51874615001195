'use client';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_SERVER_URL,
  }),
  tagTypes: ['user_subscription'],
  endpoints: (builder) => ({
    // Handling Refresh Token Query
    refreshToken: builder.query({
      query: (data) => ({
        url: '/auth/refresh-session',
        method: 'GET',
        headers: {
          Authorization: `Basic  ${Buffer.from(
            `${process.env.NEXT_PUBLIC_URL_API_USERNAME}:${
              process.env.NEXT_PUBLIC_URL_API_PASSWORD
            }`,
          ).toString('base64')}`,
        },
        credentials: 'include' as const,
      }),
    }),
    // User Load Handle query
    loadUser: builder.query({
      query: () => ({
        url: '/user/me',
        method: 'GET',
        headers: {
          Authorization: `Basic  ${Buffer.from(
            `${process.env.NEXT_PUBLIC_URL_API_USERNAME}:${
              process.env.NEXT_PUBLIC_URL_API_PASSWORD
            }`,
          ).toString('base64')}`,
        },
        credentials: 'include',
      }),
    }),
  }),
});

export const { useRefreshTokenQuery, useLoadUserQuery } = apiSlice;
